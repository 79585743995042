import { IContent } from '../../feature/core/models/content';
import { User } from '../../feature/core/models/user';

declare var MathJax: any;

enum SortType {
  ASC = 'asc',
  DSC = 'dsc',
}

export class CommonUtil {
  static queryStringToJSON(qs: string) {
    qs = qs || location.search.slice(1);

    var pairs = qs.split('&');
    var result: any = {};
    pairs.forEach(function (p) {
      var pair = p.split('=');
      var key = pair[0];
      var value = decodeURIComponent(pair[1] || '');

      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === '[object Array]') {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });

    return JSON.parse(JSON.stringify(result));
  }

  static getUser(): User {
    const user: User =
      ['undefined', null].indexOf(localStorage.getItem('user')) === -1
        ? JSON.parse(localStorage.getItem('user') || '')
        : {};
    return user;
  }

  static sortBy(list: any[], field: string, sortType: SortType = SortType.ASC) {
    if (!list || !field) return [];
    if (sortType === SortType.ASC) {
      return list.sort((a: any, b: any) => (a[field] < b[field] ? -1 : 1));
    } else if (sortType === SortType.DSC) {
      return list.sort((a: any, b: any) => (a[field] > b[field] ? 1 : -1));
    } else {
      return list;
    }
  }

  static renderImage(content: any) {
    let url;
    try {
      url = new URL(content.data);
    } catch (_) {
      return `data:${content.type};base64,${content.data}`;
    }
    return content.data;
  }

  static setListStyle(listStle: string) {
    switch (listStle) {
      case 'lowerRoman':
        return 'lower-roman';
      case 'upperRoman':
        return 'upper-roman';
      case 'decimal':
        return 'decimal';
      case 'lowerLetter':
        return 'lower-alpha';
      case 'upperLetter':
        return 'upper-alpha';
      case 'bullet':
        return 'disc';
      default:
        return '';
    }
  }

  static contentPanelStyles(c: any, acIndex: number, type: string = '') {
    return {
      ...(acIndex > 0 && { 'nw-line': c.startPosition == 'NEW_LINE' }),
      'fw-bold': c.isBold,
      'fst-italic': c.isItalic,
      'text-decoration-underline': c.isUnderlined,
      'v-align-base': c.verticalAlignment == 'baseline',
      'v-align-sub': c.verticalAlignment == 'subscript',
      'v-align-super': c.verticalAlignment == 'superscript',
      /* ...(type == 'tbl' && {
                'align-end': c.horizontalAlignment == "RIGHT",
                'align-center': c.horizontalAlignment == "CENTER"
            }), */
    };
  }

  static setTblHorizontalAlignment(contents: any[]) {
    if (contents.length > 0) {
      return contents[0].horizontalAlignment?.toLowerCase();
    }
    return '';
  }

  static renderMathJaxElementBySelectorId(
    content: IContent,
    selectorId: string
  ) {
    if (content.contentType === 'TEXT') {
      setTimeout(() => {
        const preview: any = document.getElementById(selectorId);
        if (preview) {
          preview.innerHTML = content.data;

          /* MathJax v2 */
          // MathJax.Hub.Queue(["Typeset", MathJax.Hub, preview]);

          /** MathJax v3 */
          MathJax.startup.promise; // make sure initial typesetting has taken place
          MathJax.typesetClear([preview]); // clear MathJax awareness of this element
          MathJax.typesetPromise([preview]); // typeset anew

          /* window.com.wiris.js.JsPluginViewer.parseElement(preview, true, function(){}); */
        }
      }, 0);
    }
  }

  static secondsToMinSecPadded = (time: any) => {
    const minutes: any = `${Math.floor(time / 60)}`.padStart(2, '0');
    const seconds: any = `${time - minutes * 60}`.padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  static convertToMin = (time: any) => {
    return Math.floor(time / 60);
  };

  static capitalizeFirstLetter = (str: string) => {
    const s = str.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
}
