import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-report-question-dialog',
    templateUrl: './report-question-dialog.component.html',
    styleUrls: ['./report-question-dialog.component.scss']
})
export class ReportQuestionDialogComponent{
  
  // seasons: string[] = ['Question confusing', 'Invalid information', 'Out of syllabus', 'Wrong option','Solution insufficient/confusing','Solution incorrect'];
  seasons: any[] = [{
    name:"Question confusing",
    checked:false
  },{
    name:"Invalid information",
    checked:false
  },{
    name:"Out of syllabus",
    checked:false
  },{
    name:"Wrong option",
    checked:false
  },{
    name:"Solution insufficient/confusing",
    checked:false
  },{
    name:"Solution incorrect",
    checked:false
  },{
    name:"Typing error in Question",
    checked:false
  },
  {
    name:"Question too dificult",
    checked:false
  }];

  assessmentSeasons: any[] = [{
    name:"Question confusing",
    checked:false
  },{
    name:"Invalid information",
    checked:false
  },{
    name:"Out of syllabus",
    checked:false
  },{
    name:"Wrong option",
    checked:false
  },{
    name:"Typing error in Question",
    checked:false
  },
  {
    name:"Question too dificult",
    checked:false
  }];
  
 

    reportQuestionForm: FormGroup;
    constructor(
        public dialogRef: MatDialogRef<ReportQuestionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
    ) {
      if(data && data.isAssessment){
        this.seasons = [...this.assessmentSeasons]
      }
        this.reportQuestionForm = this.formBuilder.group({
            comment: ['', [Validators.pattern(/^(?!\s)[\s\S]*$/)]],
        });
    }

   
    close() {
        this.dialogRef.close({ action: false });
    }

    submit() {
        //console.log(this.reportQuestionForm.value)
        let arr:any[] = []
        arr = this.seasons.filter(el => el.checked);
        let arr2:any[] = [];
        if (arr.length) {
              arr2 = arr.map(obj => obj.name)
              
          }
        this.dialogRef.close({ action: true, reportFormData: this.reportQuestionForm.value, selectedReason: arr2 });
        // if (arr.length) {
        //     let arr2 = arr.map(obj => obj.name)
            
        // } else {
        //     this.toastService.show('Please select the type of issue', {
        //         classname: 'toast-error',
        //         delay: 4000,
        //     });
        // }
    }

    onSelect(element: any) {
        this.seasons.forEach(el => {
            if (element == el) {
                el.checked = !el.checked;
            }
        })
    }

    get isSubmitDisabled() {
      // if(this.seasons.filter(el => el.checked).length== 0){
      //   return true
      // }
        return this.seasons.filter(el => el.checked).length== 0 && this.reportQuestionForm.controls['comment'].value == '';
    }
}
