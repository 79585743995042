<span
  mat-dialog-actions
  align="end"
  style="z-index: 4000; cursor: pointer"
  (click)="close()"
>
  <img src="../../../../../../assets/close-icon.png" alt="" />
</span>
<div class="container-fluid voucher-dialog">
  <!-- <form [formGroup]="reportQuestionForm"> -->
  <div class="row p-3">
    <div class="col-12 edit-header mt-3">
      {{ selectedGoal }}
    </div>
    <div class="col-12 sub-header">
      Choose your subject for {{ submitBtnLabel }} test
    </div>
    <div
      class="col-12 sub-list"
      style="margin-top: 10px"
      *ngIf="subjectList.length"
    >
      <ng-container *ngFor="let sub of subjectList; let i = index">
        <label for="{{ 'option' + i }}" class="subject-tile">
          <!-- <button class="btn py-0 subject-tile"> -->

          <input
            type="radio"
            name="options"
            id="{{ 'option' + i }}"
            [(ngModel)]="selectedSubject"
            [value]="sub.subjectId"
          />
          <label for="{{ 'option' + i }}" style="color: white">{{
            sub.subject
          }}</label>
          <!-- <input type="radio" name="options" id="option1" [value]="sub.subjectId"
                        [(ngModel)]="selectedSubject"> {{sub.subject}} -->

          <!-- </button> -->
        </label>
      </ng-container>
    </div>

    <div class="col-12 mt-3 d-flex justify-content-end">
      <button
        mat-raised-button
        class="save-button"
        [disabled]="selectedSubject == ''"
        (click)="startScholarship()"
      >
        {{ "Start " + submitBtnLabel + " Test" }}
      </button>
    </div>
  </div>
  <!-- </form> -->
</div>
