<div class="b2b-paginator d-flex flex-row align-items-center pt-3">
    <span class="page-label">Pages</span>
    <div class="select-pagegroup">
        <img class="pg-arrow" src="../../../../assets/icons/down-arrow-blue.svg" />
        <select class="form-select readonly-block" aria-label="Default select example" [(ngModel)]="currentPage"
            (change)="changePage($event)">
            <option *ngFor="let page of pages" [value]="page">{{page}}</option>
        </select>

    </div>
    <div class="prev-next">
        <span class="prev" [ngClass]="currentPage != 1 ? 'prev': 'prev-disabled'"  (click)="currentPage != 1 ? goToPreviousPage() : ''">Previous</span>
        <span class="nxt" [ngClass]="currentPage != pages.length ? 'nxt': 'nxt-disabled'"  (click)="currentPage != pages.length ? goToNextPage() : ''">Next</span>
    </div>

</div>