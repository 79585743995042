import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { GlobalSpinnerInterceptor } from './global-spinner/global-spinner.interceptor';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { EfDrawerComponent } from './ef-drawer/ef-drawer.component';
import { MaterialModule } from './material-module';
import { DataTableComponent } from './data-table/data-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EfPaginatorComponent } from './ef-paginator/ef-paginator.component';
import { EfMultiselectComponent } from './ef-multiselect/ef-multiselect.component';
import { B2bSelectComponent } from './b2b-select/b2b-select.component';
import { B2bDatepickerComponent } from './b2b-datepicker/b2b-datepicker.component';
import { MockMeterComponent } from './mock-meter/mock-meter.component';
import { SemiCircleRangeSliderComponent } from './semi-circle-range-slider/semi-circle-range-slider.component';
import { ChapterInsightComponent } from './chapter-insight/chapter-insight.component';
import { InsightDatagridComponent } from './insight-datagrid/insight-datagrid.component';
import { VerticalProgressStepsComponent } from './vertical-progress-steps/vertical-progress-steps.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ReportQuestionDialogComponent } from './report-question-dialog/report-question-dialog.component';
import { PreLoginFlatTextPipe } from './pipes/pre-login-flat-text.pipe';
import { IncrementalCounterComponent } from './components/incremental-counter/incremental-counter.component';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { ScholarshipSubjectModalComponent } from './scholarship-subject-modal/scholarship-subject-modal.component';
import { TimePipe } from './pipes/time.pipe';
import { AccordianComponent } from './accordian/accordian.component';
import { SafePipe } from './pipes/safe.pipe';
import { ReportSuccessMsgComponent } from './report-success-msg/report-success-msg.component';

@NgModule({
  declarations: [
    GlobalSpinnerComponent,
    ToastsContainerComponent,
    EfDrawerComponent,
    DataTableComponent,
    EfPaginatorComponent,
    EfMultiselectComponent,
    B2bSelectComponent,
    B2bDatepickerComponent,
    MockMeterComponent,
    SemiCircleRangeSliderComponent,
    ChapterInsightComponent,
    InsightDatagridComponent,
    VerticalProgressStepsComponent,
    ReportSuccessMsgComponent,
    ReportQuestionDialogComponent,
    PreLoginFlatTextPipe,
    IncrementalCounterComponent,
    CountdownTimerComponent,
    ScholarshipSubjectModalComponent,
    TimePipe,
    AccordianComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ClipboardModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    TranslateModule.forRoot(),
    NgChartsModule,
  ],
  exports: [
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MaterialModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ClipboardModule,
    GlobalSpinnerComponent,
    ToastsContainerComponent,
    EfDrawerComponent,
    DataTableComponent,
    MatDialogModule,
    EfPaginatorComponent,
    EfMultiselectComponent,
    B2bSelectComponent,
    B2bDatepickerComponent,
    MockMeterComponent,
    SemiCircleRangeSliderComponent,
    ChapterInsightComponent,
    InsightDatagridComponent,
    VerticalProgressStepsComponent,
    NgCircleProgressModule,
    PreLoginFlatTextPipe,
    IncrementalCounterComponent,
    TranslateModule,
    CountdownTimerComponent,
    NgChartsModule,
    ScholarshipSubjectModalComponent,
    TimePipe,
    AccordianComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalSpinnerInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
