import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonUtil } from '../util/common.util';

@Component({
  selector: 'app-scholarship-subject-modal',
  templateUrl: './scholarship-subject-modal.component.html',
  styleUrls: ['./scholarship-subject-modal.component.scss'],
})
export class ScholarshipSubjectModalComponent {
  // seasons: string[] = ['Question confusing', 'Invalid information', 'Out of syllabus', 'Wrong option','Solution insufficient/confusing','Solution incorrect'];
  seasons: any[] = [
    {
      name: 'Question confusing',
      checked: false,
    },
    {
      name: 'Invalid information',
      checked: false,
    },
    {
      name: 'Out of syllabus',
      checked: false,
    },
    {
      name: 'Wrong option',
      checked: false,
    },
    {
      name: 'Solution insufficient/confusing',
      checked: false,
    },
    {
      name: 'Solution incorrect',
      checked: false,
    },
    {
      name: 'Typing error in Question',
      checked: false,
    },
    {
      name: 'Question too dificult',
      checked: false,
    },
  ];

  assessmentSeasons: any[] = [
    {
      name: 'Question confusing',
      checked: false,
    },
    {
      name: 'Invalid information',
      checked: false,
    },
    {
      name: 'Out of syllabus',
      checked: false,
    },
    {
      name: 'Wrong option',
      checked: false,
    },
    {
      name: 'Typing error in Question',
      checked: false,
    },
    {
      name: 'Question too dificult',
      checked: false,
    },
  ];

  subjectList: any[] = [];
  selectedGoal: any = '';
  selectedSubject: any = '';

  reportQuestionForm: FormGroup;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ScholarshipSubjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    if (data && data.isAssessment) {
      this.seasons = [...this.assessmentSeasons];
    }
    this.reportQuestionForm = this.formBuilder.group({
      comment: ['', [Validators.pattern(/^(?!\s)[\s\S]*$/)]],
    });
  }

  ngOnInit() {
    let goalsDetails = JSON.parse(
      localStorage.getItem('selectedGoals') as string
    );
    this.selectedGoal = goalsDetails.selectedGoals[0].exams[0].exam;
    this.subjectList = goalsDetails.selectedGoals[0].exams[0].subjects;
    this.subjectList = this.sort(this.subjectList, 'displaySequence');
    // console.log(this.subjectList);
  }
  close() {
    this.dialogRef.close({ action: false });
  }

  sort(list: any, key: string = 'displaySequence') {
    return CommonUtil.sortBy(list, key);
  }

  submit() {
    //console.log(this.reportQuestionForm.value)
    let arr: any[] = [];
    arr = this.seasons.filter((el) => el.checked);
    let arr2: any[] = [];
    if (arr.length) {
      arr2 = arr.map((obj) => obj.name);
    }
    this.dialogRef.close({
      action: true,
      reportFormData: this.reportQuestionForm.value,
      selectedReason: arr2,
    });
    // if (arr.length) {
    //     let arr2 = arr.map(obj => obj.name)

    // } else {
    //     this.toastService.show('Please select the type of issue', {
    //         classname: 'toast-error',
    //         delay: 4000,
    //     });
    // }
  }

  onSelect(element: any) {
    this.seasons.forEach((el) => {
      if (element == el) {
        el.checked = !el.checked;
      }
    });
  }

  get isSubmitDisabled() {
    // if(this.seasons.filter(el => el.checked).length== 0){
    //   return true
    // }
    return (
      this.seasons.filter((el) => el.checked).length == 0 &&
      this.reportQuestionForm.controls['comment'].value == ''
    );
  }

  startScholarship() {
    this.dialogRef.close({ subjectId: this.selectedSubject });
  }

  get submitBtnLabel() {
    const isFreeTest = this.router.url.indexOf('free-test') > -1;
    return isFreeTest ? 'Free' : 'Scholarship';
  }
}
