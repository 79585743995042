import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { OrganizationService } from '../../../services/organization.service';
import { CommonUtil } from '../util/common.util';

/**
 * @title Table with selection
 */

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent
  implements AfterViewInit, OnChanges, OnDestroy, OnInit
{
  private destroyed$ = new Subject<void>();

  selection = new SelectionModel<any>(true, []);

  @Input() displayedColumns: string[] = [];
  @Input() displayedColumnsNames: any[] = [];
  @Input() dataSource: any;
  @Input() currentPage: any;
  @Input() pages: any;
  @Input() actions: any[] = [];
  @Input() filterBy: string[] = [];
  @Input() isToolbarActive: boolean = false;
  @Output() onPageChange = new EventEmitter();
  @Output() selectedAction = new EventEmitter();
  @Output() editAction = new EventEmitter();
  @Output() onFilter = new EventEmitter();

  classOptions: any = [{ label: 'All', value: '' }];
  sectionOptions: any = [];
  partnetDetails: any[] = [];

  selectedClass: string = '';
  selectedSection: any[] = [];
  searchByNameTxt: string = '';
  filterSection: any = {};
  editFieldDetails: any = null;

  @ViewChild(MatPaginator) paginator: any;

  selectedProducts: any[] = [];
  selectedRows: any[] = [];
  filterObj: any = {};

  studentColumns: string[] = [];

  chipConfig: any = {
    cross: true,
  };

  constructor(private organizationService: OrganizationService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.selection.clear(); //clear pre exixting selection when any action is done and fresh table data is invoked
    // console.log(this.dataSource)
    /* if (this.dataSource) {
                // console.log(this.dataSource)
                // this.paginator = this.dataSource.paginator;
            } */

    /** Edit Action Column */
    if (changes['filterBy'] && changes['filterBy'].currentValue) {
      this.filterBy.map((o: any) => {
        this.filterSection[o] = true;
      });
    }
    if (
      changes['displayedColumns'] &&
      changes['displayedColumns'].currentValue
    ) {
      const f = this.displayedColumns.filter(
        (col: any) => col.indexOf('-edit') > 0
      );

      if (f.length > 0) {
        const fieldName = f[0].split('-edit')[0];
        const fObj = this.displayedColumnsNames.filter(
          (f: any) => f.field === fieldName
        );

        if (fObj.length > 0) {
          this.editFieldDetails = { ...fObj[0], colDef: f[0] };
        } else {
          this.editFieldDetails = {
            field: fieldName,
            header: CommonUtil.capitalizeFirstLetter(fieldName),
            colDef: f[0],
          };
        }
      }

      /** removing checkbox column for home page */
      this.studentColumns = !this.isToolbarActive
        ? this.displayedColumns.slice(1)
        : this.displayedColumns;
    }
    /** Edit Action Column end */
  }

  ngOnInit(): void {
    this.getGradeList();
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.paginator = this.dataSource.paginator;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getGradeList() {
    const param = { includeSection: true, query: 'getGradeList' };
    this.organizationService
      .getPartner(param)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: any) => {
          this.partnetDetails = res;
          this.partnetDetails.map((r: any) => {
            this.classOptions.push({ label: r.gradeDesc, value: r.gradeCode });
          });
          this.selectedClass = '';
          if (this.selectedClass) {
            this.fetchSectionOnGrade(this.selectedClass);
            this.filterObj.gradeId = this.selectedClass;
            this.onFilter.emit(this.filterObj);
          }
        },
        error: (err: any) => {
          console.warn(err);
        },
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getInitials(name: any) {
    let a = name.split(' ');
    let initial = '';
    for (let i = 0; i < a.length; i++) {
      initial = initial + a[i].split('')[0].toUpperCase();
    }
    return initial;
  }

  onAction(element: any) {
    // console.log(element)
    this.editAction.emit(element);
  }

  selectRow(checkValue: any) {
    // console.log(checkValue)
    if (checkValue) {
      this.selectedRows = this.dataSource;
    } else {
      this.selectedRows = [];
    }
  }
  viewSelected() {
    // console.log(this.selectedRows);
  }

  changePage(currentPage: any) {
    // console.log(currentPage);
    this.onPageChange.emit(currentPage);
    // console.log(this.currentPage);
  }

  takeSelectedAction(action: any) {
    // console.log(action)
    // console.log(this.selection.selected)
    this.selectedAction.emit({
      selectedRows: this.selection.selected,
      action: action,
    });
  }

  onChangeClass(item: any) {
    this.selectedSection = [];
    this.searchByNameTxt = '';
    this.filterObj.searchKey = '';
    if (item.value) {
      // console.log(item);
      this.fetchSectionOnGrade(item.value);

      this.filterObj.gradeId = item.value;

      this.onFilter.emit(this.filterObj);
    } else if (item.value == '') {
      this.sectionOptions = [];
      delete this.filterObj.gradeId;
      delete this.filterObj.sectionId;
      this.onFilter.emit(this.filterObj);
    }
  }
  onChangeSection(item: any) {
    // console.log(item);
    if (this.selectedSection.includes(item)) {
      const i = this.selectedSection.indexOf(item);
      if (i > -1) {
        this.selectedSection.splice(i, 1);
      }
    } else {
      this.selectedSection.push(item);
    }
    this.selectedSection = this.selectedSection.slice();
    this.filterObj.sectionId = this.selectedSection.map(
      (sec: any) => sec.value
    );
    this.onFilter.emit(this.filterObj);
  }
  onChangeName(ev: any) {
    // console.log(ev.target.value);
    this.filterObj.searchKey = ev.target.value;
    this.onFilter.emit(this.filterObj);
  }

  fetchSectionOnGrade(gradeId: string) {
    const activeClassDetails = this.partnetDetails.find(
      (pd: any) => pd.gradeCode === gradeId
    );
    this.sectionOptions = activeClassDetails.sectionList.map((sec: any) => ({
      name: sec.sectionTag,
      value: sec.id,
    }));
  }
  getLabel(enrollmentStatus: any) {
    let a = this.actions.filter((el) => el.value == enrollmentStatus);
    return a[0].label;
  }
}
