<div class="b2b-data-table">

    <ng-container *ngIf="isToolbarActive">
        <div class="search-section row">
            <div class="col-2 mb-3 b2b-form-ctrl-wrapper">
                <ng-container *ngIf="filterSection.grade">
                    <app-b2b-select [options]="classOptions" [value]="selectedClass"
                        (onChange)="onChangeClass($event)"></app-b2b-select>
                </ng-container>
            </div>
            <div class="col-3 mb-3 b2b-form-ctrl-wrapper" *ngIf="filterSection.section">
                <app-ef-multiselect [selectedOptions]="selectedSection" [allOptions]="sectionOptions"
                    (selectOption)="onChangeSection($event)" [config]="chipConfig" (onRemove)="onChangeSection($event)"></app-ef-multiselect>
            </div>
            <div class="col-3 mb-3 b2b-form-ctrl-wrapper">
                <ng-container *ngIf="filterSection.name">
                    <div class="input-group">
                        <div class="input-group-text">
                            <img src="../../../../assets/icons/student/search-icon.svg" />
                        </div>
                        <input type="text" class="form-control ps-0" placeholder="Search by Name"
                            [(ngModel)]="searchByNameTxt" (keyup.enter)="onChangeName($event)">
                    </div>
                </ng-container>
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center"
                [ngClass]="filterSection.section ? 'offset-3': 'offset-6'">
                <button class="btn ef-b2b-link-btn"><img src="../../../../assets/icons/download_icon.png" /></button>
            </div>
        </div>
        <div *ngIf="selection.selected.length" class="action-div">
            <span class="row-count">{{selection.selected.length}} row selected</span>
            <span class="select-options" *ngFor="let item of actions"
                (click)="takeSelectedAction(item.value)">{{item.label}}</span>
            <!-- <span class="select-options" (click)="takeSelectedAction('inactive')">In-Active</span>
        <span class="select-options" (click)="takeSelectedAction('archive')">Archive</span>
        <span class="select-options" (click)="takeSelectedAction('delete')">Delete</span> -->
        </div>
    </ng-container>

    <div class="mat-elevation-z8 b2b-table-wrapper" *ngIf="dataSource">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
            style="background-color: #09070e;border: 1px solid #2f2f2f">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style="color:#F1F1F1;background-color: #191924;" width="5%">
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" style="color:#F1F1F1">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container *ngFor="let columndata of displayedColumnsNames" [matColumnDef]="columndata.field">
                <th mat-header-cell *matHeaderCellDef style="color:#F1F1F1;background-color: #191924;">
                    {{columndata.header}} </th>
                <td mat-cell *matCellDef="let element" style="color:#F1F1F1;">
                    <div *ngIf="columndata.field == 'name'" class="name-col"><span
                            class="name-initials">{{getInitials(element[columndata.field])}}</span>
                        {{element[columndata.field]}}</div>
                    <div *ngIf="columndata.field != 'name'">{{(element[columndata.field] != null && element[columndata.field] != "") ? element[columndata.field] : "--"}}</div>

                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="color:#F1F1F1;background-color: #191924;">Class & Subject
                </th>
                <td mat-cell *matCellDef="let element"  style="color:#F1F1F1"> <span class="view-btn" *ngIf="element.assignedSubjectPresent == false"
                        (click)="onAction(element)">Assign Subjects</span><span class="view-btn" *ngIf="element.assignedSubjectPresent == true"
                        (click)="onAction(element)">View Assign Subjects</span></td>
            </ng-container>
            <ng-container matColumnDef="{{editFieldDetails.colDef}}" *ngIf="editFieldDetails">
                <th mat-header-cell *matHeaderCellDef style="color:#F1F1F1;background-color: #191924;">
                    {{editFieldDetails.header}}
                </th>
                <td mat-cell *matCellDef="let element" style="color:#F1F1F1"> <span class="edit-btn"
                        (click)="onAction(element)">{{element[editFieldDetails.field]}}<img class="ps-2"
                            *ngIf="element[editFieldDetails.field]"
                            src="../../../../assets/icons/student/edit-icon.svg" /></span></td>
            </ng-container>
            <ng-container matColumnDef="enrollmentStatus">
                <th mat-header-cell *matHeaderCellDef style="color:#F1F1F1;background-color: #191924;"> Enrollment
                    Status
                </th>
                <td mat-cell *matCellDef="let element" style="color:#F1F1F1"> <span
                        [ngClass]="element.enrollmentStatus.toUpperCase() == 'ACTIVE' ? 'active-enroll':'inactive-enroll'">{{getLabel(element.enrollmentStatus)}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="studentColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: studentColumns;" (click)="selection.toggle(row)">
            </tr>
        </table>

    </div>

    <ng-container *ngIf="isToolbarActive">
        <app-ef-paginator [currentPage]="currentPage" [pages]="pages"
            (onPageChange)="changePage($event)"></app-ef-paginator>
    </ng-container>
</div>