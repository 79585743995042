import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insight-datagrid',
  templateUrl: './insight-datagrid.component.html',
  styleUrls: ['./insight-datagrid.component.scss']
})
export class InsightDatagridComponent implements OnInit {
  @Input() assessmentData:any;

  constructor(private router:Router){}

  ngOnInit(){
    console.log('assessmentData',this.assessmentData);
  }

  goToReport(tdata:any){
    //console.log("goToReport ",tdata);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/assessment-player/${tdata.partnerId}/${tdata.assessmentId}/report`],{ queryParams: { resultId: tdata.id} })
    );
  
    window.open(url, '_blank');
  }
}
