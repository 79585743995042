import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  endWith,
  interval,
  map,
  startWith,
  Subscription,
  take,
  takeWhile,
  timer,
} from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {
  @Input('minutes')
  minutes!: number;

  seconds!: number;

  obsrver!: Subscription;

  @Output('onComplete')
  onComplete: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onCount(secs: number, oncomplete: any) {
    let gameStartTimestamp = Date.now();
    let gameEndTimestamp = gameStartTimestamp + secs * 1000;

    console.log('Start ', new Date(gameStartTimestamp));
    console.log('End ', new Date(gameEndTimestamp));

    this.obsrver = interval(1000)
      .pipe(
        map(() => Date.now()),
        takeWhile((now) => now < gameEndTimestamp),
        map((now) => (gameEndTimestamp - now) / 1000),
        endWith(0)
      )
      .subscribe({
        next: (x: any) => {
          this.seconds = Math.round(x);
        },
        complete: () => {
          console.log('Complete fire ', new Date());
          oncomplete.emit('Success');
        },
      });
    // timer(100, 1000).pipe(map(i => secs - i), take(secs + 1))
    //   // timer(firstValueDelay, intervalBetweenValues)
    //   // .map(i => start - i)
    //   // .take(start + 1)

    //   //   let scnd = secs;
    //   //   interval(1000)
    //   //   .pipe(take(secs))
    //   //   .pipe(map(x => scnd--))
    //   .subscribe({
    //     next: (x: any) => { this.seconds = x; },
    //     complete: () => oncomplete.emit('Success')
    //   });
  }

  ngOnInit() {
    this.seconds = this.minutes * 60;
    this.onCount(this.seconds, this.onComplete);
  }

  ngOnDestroy(): void {
    if (this.obsrver) {
      this.obsrver.unsubscribe();
    }
  }
}
