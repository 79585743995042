import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, mergeMap, of, Subject } from 'rxjs';
import { AuthApiService } from '../../../services/auth.service';
import { DecodeToken } from './util/token-decod.util';

@Injectable({ providedIn: 'root' })
export class AuthService {
  tokenEventNotifier = new Subject<{ type: string }>();
  authorizationHeaderName = 'Authorization';
  bearerPrefix = 'Bearer';
  inUpdating = false;
  watcher: any;

  constructor(
    private router: Router,
    private decodeToken: DecodeToken,
    private authApiService: AuthApiService
  ) {}

  public async isLoggedIn() {
    try {
      let token = await this.decodedToken();
      let tokenExp = token.exp * 1000;
      return new Date().getTime() < tokenExp;
    } catch (error) {
      return false;
    }
  }

  public login() {
    //
  }

  public logout() {
    return from(this.refreshToken).pipe(
      mergeMap((refreshToken) =>
        this.authApiService.requestLogout({ refreshToken })
      )
    );
  }
  public async refresh() {
    await this.updateToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public async accessToken() {
    let rt = localStorage.getItem('access_token');
    if (rt) {
      return Promise.resolve(rt);
    } else {
      return Promise.reject('Not found');
    }
  }
  public get refreshToken() {
    let rt = localStorage.getItem('refresh_token');
    if (rt) {
      return Promise.resolve(rt);
    } else {
      return Promise.reject('Not found');
    }
  }

  public async roles() {
    return this.decodeToken.decodeToken(await this.accessToken()).realm_access
      .roles;
  }

  public async permissions(): Promise<string[]> {
    let allPermissions: any = this.decodeToken.decodeToken(
      await this.accessToken()
    ).resource_access;
    let permissions: string[] = [];
    for (let permission in allPermissions) {
      permissions = [...permissions, ...allPermissions[permission]['roles']];
    }
    return permissions;
  }

  public async userType(): Promise<string> {
    return this.decodeToken.decodeToken(await this.accessToken()).userType;
  }

  public async managePartner(): Promise<string[]> {
    return this.decodeToken.decodeToken(await this.accessToken())
      .managePartners;
  }

  public async decodedToken() {
    return await this.decodeToken.decodeToken(await this.accessToken());
  }

  public async userName() {
    return await this.decodeToken.decodeToken(await this.accessToken())
      .preferred_username;
  }

  public async email() {
    return await this.decodeToken.decodeToken(await this.accessToken()).email;
  }

  public registerAutoRefresh() {
    // console.log("Register auto refresh");
    var self = this;

    if (!this.watcher) {
      this.updateToken();
    }

    // this.tokenEventNotifier.asObservable()
    //   .subscribe({
    //     next(event) {
    //       if (event.type == "EXPIRED") {
    //         console.log("execute token refresh");
    //         self.refresh();
    //       }
    //     }
    //   });
  }

  public addTokenToHeader(req: HttpRequest<unknown>) {
    let token = localStorage.getItem('access_token') as string;
    if (token) {
      req.headers.set(
        this.authorizationHeaderName,
        this.bearerPrefix + ' ' + token
      );
    }
    return of(req.headers);
  }

  public registerAndWatch(iCustomLoginSuccessResponse: any) {
    for (let key in iCustomLoginSuccessResponse) {
      localStorage.setItem(key, iCustomLoginSuccessResponse[key]);
    }
    let expIn = iCustomLoginSuccessResponse['expires_in'];
    this.watcher = setTimeout(() => {
      //this.tokenEventNotifier.next({ type: 'EXPIRE' });
      this.updateToken();
    }, expIn * 1000);
  }

  public updateToken() {
    try {
      from(this.refreshToken)
        .pipe(
          mergeMap((rf) =>
            this.authApiService.refreshToken({ refreshToken: rf as string })
          )
        )
        .subscribe((newTokwnRes) => {
          this.registerAndWatch(newTokwnRes);
        });
    } catch (error) {
      console.log('erroe', error);
    }
  }

  public async getExpInSec() {
    let at = await this.decodedToken();
    let leftMili = (at.exp - new Date().getTime()) / 1000;
    return leftMili > 0 ? leftMili : 0;
  }
}
