<div class="chapter-insight-section">
  <div class="position-relative mb-3">
    <div class="control-bar" *ngIf="unitList?.length">
      <button class="btn btn-bg-style-left" (click)="scrollToLeft()">
        <img src="../../../../../../assets/icons/home/carousel-left-nav.svg" />
      </button>
      <button
        class="btn scrollToRight btn-bg-style-right"
        (click)="scrollToRight()"
      >
        <img src="../../../../../../assets/icons/home/carousel-right-nav.svg" />
      </button>
    </div>
    <div class="units-block" #unitBar>
      <div class="lms-radio-toggle-btn" *ngFor="let unit of unitList">
        <input
          type="radio"
          class="btn-check"
          name="opt-{{ unit }}"
          id="{{ unit }}"
          autocomplete="off"
          (change)="changeUnit(unit)"
          [checked]="selectedUnit == unit"
        />
        <label class="btn" for="{{ unit }}"
          ><span>{{ unit }}</span></label
        >
      </div>
    </div>
<div class="hapters-block-scrollWrapper">
    <div
      class="chapters-block"
      *ngFor="let chapter of chapterInsightList; let i = index"
    >
      <div class="content-card mb-2" (click)="(chapter.strongAreaCount > 0?goToChapter(chapter,i):'')">
        <div class="title mb-2">C-{{ i + 1 }} {{ chapter.chapter }}</div>
        <div class="content-info">
          {{ chapter.strongAreaCount }} strong and
          {{ chapter.weakAreaCount }} weak areas identified in practice
        </div>
        <button class="lock-unlock-icon" *ngIf="chapter.strongAreaCount > 0">
          <i class="fa" [ngClass]="{'fa-angle-down' : openIndex === i,'fa-angle-up' : openIndex !== i}"></i>
        </button>
        <ng-container *ngIf="openIndex == i">
          <div class="chapter-topic-list" *ngFor="let topics of insightSubChapters">
            <h4>{{subKeyVal.get(topics?.topicId)}}</h4>
            <ul >
              <li *ngFor="let subTopics of topics?.subTopicSummarylist">{{subKeyVal.get(subTopics?.subTopicId)}} <i class="fa fa-star" *ngIf="subTopics?.passed"></i></li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  </div>
</div>
