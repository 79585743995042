/* import { defaultEnvironment } from '../config/default.env'; */
import { DeaultEnvironmentConfig } from "./default.env";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

/* export const environment = {
  ...defaultEnvironment
}; */

class EnvironmentConfig extends DeaultEnvironmentConfig {
  override production = false;
  apiUrl = 'http://localhost:4300';
  apiEndpoint = 'http://localhost:8081';
}

export const environment = new EnvironmentConfig();
