<mat-form-field appearance="outline" class="b2b-datepicker w-100">
  <input
    matInput
    [matDatepicker]="picker"
    (click)="picker.open()"
    [(ngModel)]="value"
    (dateChange)="dateChange($event)"
    [matDatepickerFilter]="filterDate"
    readonly
  />
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
