import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StudentService } from '../../../services/student.service';
import { MasterDataService } from '../../../services/master-data.service';

@Component({
  selector: 'app-chapter-insight',
  templateUrl: './chapter-insight.component.html',
  styleUrls: ['./chapter-insight.component.scss'],
})
export class ChapterInsightComponent implements OnChanges {
  @Input() chapterInsightData: any;
  @Input() selectedSubject: any;
  @ViewChild('unitBar') unitBar!: ElementRef<HTMLElement>;
  isDesktop: boolean = true;

  selectedUnit: any;
  chapterInsightList: any[] = [];
  unitList: any[] = [];
  studentDetails:any = {};
  openIndex:number = -1;
  insightSubChapters:any = [];
  subKeyVal = new Map<string, string>();
  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private studentService: StudentService,
    private masterDataService: MasterDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.chapterInsightData);
    console.log(this.selectedSubject);
    if (this.chapterInsightData) {
      let ulist: any[] = [];
      for (const key in this.chapterInsightData) {
        ulist.push(key);
      }
      this.unitList = ulist;
      this.selectedUnit = this.unitList[0];
      this.changeUnit(this.selectedUnit);
    } else {
      this.unitList = [];
      this.selectedUnit = {};
      this.chapterInsightList = [];
      console.log(this.unitList);
      console.log(this.selectedUnit);
      console.log(this.chapterInsightList);
    }
    if(this.selectedSubject) {
      //{ query: 'mapped_diagnostic_subtopic' }
      this.masterDataService.getSubjectBySubjectId(this.selectedSubject.subjectCode).subscribe((res)=> {
        console.log("res ",res);
        res?.units.forEach((unit)=> {
          unit?.chapters.forEach((chapter)=> {
            chapter?.topics.forEach((topic)=> {
              this.subKeyVal.set(topic.topicId,topic.displayNames[0].name);
              topic?.subTopics.forEach((subTopic)=> {
                this.subKeyVal.set(subTopic.subTopicId,subTopic.displayNames[0].name);
              });
              topic?.diagnosticSubTopics.forEach((dsubTopic)=> {
                this.subKeyVal.set(dsubTopic.diagnosticSubTopicId,dsubTopic.displayNames[0].name);
              });
            });
          });
        });
      });
    }
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.studentDetails = JSON.parse(localStorage.getItem('studentDetails') || '{}');
  }

  scrollToLeft() {
    const unitBarRef: any = this.unitBar.nativeElement;
    unitBarRef.scroll({
      left: this.unitBar.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }
  scrollToRight() {
    const unitBarRef: any = this.unitBar.nativeElement;
    unitBarRef.scroll({
      left: this.unitBar.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  changeUnit(unit: any) {
    console.log(unit);
    this.selectedUnit = unit;
    this.openIndex = -1;
    for (const key in this.chapterInsightData) {
      if (key == unit) {
        console.log(this.chapterInsightData[key]);
        this.chapterInsightList = this.chapterInsightData[key];
        console.log(this.chapterInsightList);
      }
    }
  }
  goToChapter(chapter: any,openIndex:number) {
    if(this.openIndex !== openIndex){
      this.insightSubChapters = [];
      this.openIndex = openIndex;
      let queryParams = {
        sectionId: this.studentDetails.partnerSectionId,
        chapterId : chapter.chapterId,
        studentId : this.studentDetails.id
      }
      this.studentService.getInsightSubChapters(this.selectedSubject.subjectCode,queryParams).subscribe((res: any) => {
        console.log("getInsightSubChapters ",res);
        this.insightSubChapters = res;
      });
    }else{
      this.openIndex = -1;
      this.insightSubChapters = [];
    }
    
    
    // console.log(chapter);
    // console.log(this.selectedSubject.subjectCode)
    // this.router.navigate(['/practice/landing/preparation/landing'], {
    //   queryParams: {
    //     subjectId: this.selectedSubject.subjectCode,
    //     chapterId: chapter.chapterId,
    //   },
    // });
    // let data = {
    //   chapterName: chapter.chapter,
    //   isComplete: true,
    // };
    // this.studentService.chapterInsightdata = data;
    // if (this.isDesktop) {
    //   this.router.navigate(['/practice/preparation']);
    // } else {
    //   this.router.navigate(['/practice/preparation/chapters']);
    // }
  }
}
