import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const orgServiceAPIURL = environment.organizationService;

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getPartnerSections(
    pageRequest: any = { size: 100, page: 0, activeFlag: true }
  ): Observable<any> {
    return this.http.get<any>(`${orgServiceAPIURL}/v1/partnerSections`, {
      params: {
        ...pageRequest,
      },
    });
  }

  getPartner(pageRequest: any = { size: 100, page: 0 }): Observable<any> {
    return this.http.get<any>(`${orgServiceAPIURL}/v1/partners`, {
      params: {
        ...pageRequest,
      },
    });
  }

  getPartnerById(partnerId:string): Observable<any> {
    return this.http.get<any>(`${orgServiceAPIURL}/v1/partners/${partnerId}`);
  }
}
