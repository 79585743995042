<div class="slider-container">
  <div
    class="d-flex flex-column justify-content-center align-items-center h-100"
  >
    <div class="position-relative" style="width: 273px">
      <div class="position-absolute subject-name">
        <span>{{ data?.subject }}</span>
      </div>
      <svg class="progress-container">
        <path id="arc1" #arc1 fill="none" stroke-linecap="round" />
        <path id="arc2" #arc2 fill="none" stroke-linecap="round" />
        <path id="arc3" #arc3 fill="none" stroke-linecap="round" />
        <circle
          id="circle"
          #circle
          cx="150"
          cy="150"
          stroke="#000"
          fill="orange"
        />
      </svg>

      <div
        class="position-absolute w-100 d-flex justify-content-center score-section"
        #status
      ></div>
      <div class="position-absolute info-content">
        <div class="sub-content">
          <span>{{ data?.achievableScore?.toFixed(0) }}%</span> Achievable Score
        </div>
      </div>
      <div class="position-absolute other-info">
        <div class="d-flex justify-content-between">
          <div class="description">Progress</div>
          <div class="description">
            {{ data?.completionPercentage?.toFixed(0) }}% complete
          </div>
        </div>
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped bg-success progress-bar-animated"
            role="progressbar"
            [style.width]="(data?.completionPercentage || 0) + '%'"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
