<mat-select class="b2b-multi-select form-select" aria-label="Default select example" [value]="selectedOptions"
  panelClass="b2b-multi-select-panel" multiple>
  <mat-select-trigger>
    <div class="selected-values-wrapper">
      <span class="selectedOptions" *ngFor="let option of selectedOptions">
        <span>{{option.name}}</span>
        <span *ngIf="config.cross" (click)="onClose(option)"><img src="../../../../assets/icons/close-icon-blue.svg"></span>
      </span>
    </div>

  </mat-select-trigger>
  <mat-option *ngFor="let option of allOptions" [value]="option"
    (click)="selectAction(option)">{{option.name}}</mat-option>
</mat-select>