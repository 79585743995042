<span mat-dialog-actions align="end" style="z-index: 4000;cursor: pointer;" (click)="close()">
    <img src="../../../../../assets/close-icon.png" alt="">
</span>
<div class="container-fluid voucher-dialog ">
    <form [formGroup]="reportQuestionForm">
        <div class="row p-3">
            <div class="col-12 edit-header mt-3">
                Please select the type of issue
            </div>
            <div class="col-12 reason-container" style="margin-top: 10px;">

                <button class="btn outline-btn" [ngClass]="season.checked ? 'selected-season' : 'season'"
                    *ngFor="let season of seasons;let index" (click)="onSelect(index)">
                    <span [ngClass]="season.checked ? 'selected-season-span' : 'season-span'">{{season.name}}</span>
                </button>
            </div>

            <!-- <div class="form-group col-12 mt-1">
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="d-flex flex-column"
                  formControlName="reportReasonType"
                  >
                  <mat-radio-button *ngFor="let season of seasons" [value]="season">
                    <span class="lebel-style">{{season}}</span>
                  </mat-radio-button>
                </mat-radio-group>
            </div> -->

            <div class="form-group col-12 mt-3">
                <textarea class="form-control text-area-feedback mt-2" id="feedBack" rows="3"
                    placeholder="Please briefly describe the issue" formControlName="comment"></textarea>
            </div>
            <div class="text-danger col-12" *ngIf="reportQuestionForm.controls['comment'].hasError('pattern')">
                Space is not allowed in the start.
            </div>

            <div class="col-12 mt-3 d-flex justify-content-end">
                <button mat-raised-button class="save-button"
                    [disabled]="isSubmitDisabled"
                    (click)="submit()">SUBMIT
                    <!-- <img src="../../../../../../assets/arrow.png" alt=""> -->
                </button>
            </div>
        </div>
    </form>
</div>