import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/feature/post-login/post-login.module').then(
        (m) => m.PostLoginModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/feature/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'practice-player',
    loadChildren: () =>
      import('./modules/feature/practice-player/exam/exam.module').then(
        (m) => m.ExamModule
      ),
    data: {
      userTypes: ['*'],
    },
  },
  {
    path: 'assessment-player',
    loadChildren: () =>
      import('./modules/feature/assessment-player/exam/exam.module').then(
        (m) => m.ExamModule
      ),
    data: {
      userTypes: ['*'],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
