export class DeaultEnvironmentConfig {
  production = true;

  rootApiUrl = 'https://api.dev.examfactor.co';
  // rootApiUrl = 'http://localhost:80';

  get authConfig() {
    return {
      //issuer: 'https://demo.identityserver.io',
      issuer: 'https://identity.dev.examfactor.co',
      realm: 'exam-factor',
      clientId: 'lms-client', // The "Auth Code + PKCE" client
      responseType: 'code',
      redirectUri: `${window.location.href}?_=123`,
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope: 'openid profile email roles phone', // Ask offline_access to support refresh token refreshes
      useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
      sessionChecksEnabled: true,
      showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
      clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
      nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
      enableBearerInterceptor: true,
      excludedUrls: [],
    };
  }

  shouldAddToken(request: any) {
    return true;
  }

  get allowedUrls() {
    return [this.rootApiUrl];
  }

  get carouselService() {
    return `${this.rootApiUrl}/carousel-service`;
  }

  get assessmentService() {
    return `${this.rootApiUrl}/assessment-service`;
  }

  get teacherService() {
    return `${this.rootApiUrl}/teacher-service`;
  }

  get organizationService() {
    return `${this.rootApiUrl}/organization-service`;
  }

  get authService() {
    return `${this.rootApiUrl}/auth-service`;
  }

  get studentService() {
    return `${this.rootApiUrl}/b2b-student-service`;
  }

  get testimonialService() {
    return `${this.rootApiUrl}/testimonial-service`;
  }

  get masterDataService() {
    return `${this.rootApiUrl}/master-data-service`;
  }

  get faqService() {
    return `${this.rootApiUrl}/faq-service`;
  }

  get orderService() {
    return `${this.rootApiUrl}/order-service`;
  }
}
