import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private http: HttpClient) {}

  getSubjectBySubjectId(subjectId: string, queryParam: any = {}) {
    return this.http.get<any>(
      `${environment.masterDataService}/v1/subjects/${subjectId}`,
      {
        params: { ...queryParam },
      }
    );
  }

  getChapterBySubject(subjectId: string) {
    return this.http.get<any>(
      `${environment.masterDataService}/v1/subjects/${subjectId}/chapters`
    );
  }

  getTopicsByChapter(
    subjectId: string,
    unitId: string,
    chapterId: string,
    mode: string = 'practice'
  ) {
    return this.http.get<any>(
      `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}?module=${mode}`
    );
  }

  getSubjectsByExamId(examId: string) {
    return this.http.get<any>(
      `${environment.masterDataService}/v1/exams/${examId}`
    );
  }

  getExams(categoryId: string, gradeId: string) {
    return this.http.get<any>(`${environment.masterDataService}/v1/exams`, {
      params: {
        categoryId: categoryId,
        gradeId: gradeId,
        size: 10,
        page: 0,
        active: true,
      },
    });
  }

  getAllStates() {
    return this.http.get<any>(`${environment.masterDataService}/v1/states`);
  }

  getStateById(stateId: string) {
    return this.http.get<any>(
      `${environment.masterDataService}/v1/states/${stateId}`
    );
  }
}
