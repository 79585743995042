<div class="slider-container">
  <div
    class="d-flex flex-column justify-content-center align-items-center h-100"
  >
    <div class="position-relative" style="width: 273px">
      <div class="position-absolute subject-name">
        <span>{{ data.subject }}</span>
      </div>
      <svg class="progress-container">
        <path id="arc1" #arc1 fill="none" stroke-linecap="round" />
        <path id="arc2" #arc2 fill="none" stroke-linecap="round" />
        <path id="arc3" #arc3 fill="none" stroke-linecap="round" />
        <circle
          id="circle"
          #circle
          cx="150"
          cy="150"
          stroke="#fff"
          fill="orange"
        />
      </svg>

      <div
        class="position-absolute w-100 d-flex justify-content-center score-section align-items-center"
        #status
      ></div>
      <div class="position-absolute info-content">
        <div class="content">Achievable Score</div>
        <div class="sub-content">based on chapters covered till date</div>
      </div>
      <div class="position-absolute other-info">
        <div class="d-flex justify-content-between">
          <div class="description">Overall Progress</div>
          <div class="description">
            {{ data?.completionPercentage }}% complete
          </div>
        </div>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            [style.width]="(data?.completionPercentage / 2 || 0) + '%'"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div
          class="text-center pt-2"
          [ngClass]="{ invisible: !showImproveBtn }"
        >
          <button type="button" class="btn improve-btn" (click)="doImprove()">
            <span>{{
              data?.completionPercentage
                ? "Attempt Homework"
                : "Attempt Homework"
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
