import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-b2b-select',
  templateUrl: './b2b-select.component.html',
  styleUrls: ['./b2b-select.component.scss'],
})
export class B2bSelectComponent implements OnChanges {
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;
  @Input('options') options: any = [];
  @Input('value') value: string = '';
  @Output() onChange = new EventEmitter();

  selectedValue: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['value'] && changes['value'].currentValue) ||
      (changes['options'] && changes['options'].currentValue)
    ) {
      this.filterValue();
    }
  }

  filterValue() {
    const f = this.options.filter((op: any) => op.value === this.value);
    this.selectedValue = f.length > 0 ? f[0] : {};
  }

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth - 32,
    };
  }

  openMenu(menu: any) {
    menu.openMenu();
  }

  onSelectionChange(item: any) {
    this.selectedValue = item;
    this.onChange.emit(item);
  }
}
