<div *ngIf="assessmentData?.length">
  <div class="col test-data" *ngFor="let tdata of assessmentData">
    <h3 style="font-weight: bold; margin-bottom: 5px">
      {{ tdata.assessmentName }}
    </h3>
    <small style="font-style: italic; font-size: small"
      >Completed on {{ tdata.testEndTimeStamp | date }}</small
    >
    <div class="test-scores">
      <div class="row-data" style="border-bottom: 1px solid #706868">
        <span>Accuracy percentage</span
        ><span>{{ tdata.percentage.toFixed(2) }}%</span>
      </div>
      <div class="row-data">
        <span>Score</span><span>{{ tdata.score }}/{{ tdata.fullMark }}</span>
      </div>
    </div>
    <button class="btn report-link" (click)="goToReport(tdata)">
      <span>View Complete Report</span>
      <img
        class="ms-2"
        src="../../../../../../assets/icons/assessment/see-more-icon.svg"
      />
    </button>
  </div>
</div>
