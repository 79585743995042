import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { IStudent } from './models/student';
import { Page } from '../modules/feature/core/models/page';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  chapterInsightdata: any;
  profileUpdate$: any = new Subject();

  constructor(private http: HttpClient) {}

  getStudentById(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/b2b/student/${id}`
    );
  }

  updateStudent(payload: IStudent, id: string) {
    return this.http.put<any>(
      `${environment.studentService}/v1/b2b/student/${id}`,
      payload
    );
  }

  setMyProfilePic(formData: any): Observable<any> {
    return this.http.patch(
      `${environment.studentService}/v1/b2b/student/pic`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  actionOnStudent(payload: any, action: string) {
    return this.http.patch<any>(
      `${environment.studentService}/v1/b2b/student`,
      payload,
      {
        params: {
          action,
        },
      }
    );
  }

  getSummaryReport(reqParams: any) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-summary-report`,
      {
        params: {
          ...reqParams,
        },
      }
    );
  }

  getSubInsightTimeSpend(sid: string) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-subject-insight/time/spend/${sid}`
    );
  }

  getSubInsight(subId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-subject-insight/${subId}`
    );
  }
  getInsightAssessment(sid: any) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-subject-insight/${sid}/assessmentdetails`
    );
  }

  getInsightChapters(sid: any) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-subject-insight/${sid}/chapters?q=st`
    );
  }

  getInsightSubChapters(sid: any, queryParams: any) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-subject-insight/${sid}/chapters?q=hw-chpt-drilldown`,
      {
        params: queryParams,
      }
    );
  }

  getAllBookmark(pagerequest: any) {
    return this.http.get<any>(`${environment.studentService}/v1/my-bookmark`, {
      params: pagerequest,
    });
  }

  getSubBookmarkChapter(sid: string) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-bookmark/subject-chapter/${sid}?action=all-chapter`
    );
  }

  unBookmark(qid: any, activityType: string): Observable<any> {
    return this.http.patch<any>(
      `${environment.studentService}/v1/my-bookmark/${qid}`,
      '',
      {
        params: {
          action: 'undoBookmark',
          activityType,
        },
      }
    );
  }

  getStudentAssessmentReport(assId: string) {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-classWork/${assId}`
    );
  }

  getSocialService(): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/social-proof-text`
    );
  }

  getTestimonial(pageRequest: any): Observable<Page<any>> {
    return this.http.get<Page<any>>(
      `${environment.testimonialService}/v1/testimonial`,
      {
        params: {
          ...pageRequest,
          size: 10,
          page: 0,
          active: true,
        },
      }
    );
  }

  fetchPracticeNextQuestion(
    pid: string,
    practiceId: string,
    payload: any,
    action: string = ''
  ) {
    //console.log(typeof payload);
    return this.http.patch<any>(
      `${environment.studentService}/v1/practices/${practiceId}?action=${action}`,
      payload
    );
  }

  fetchPracticeFirstQuestion(pid: string, payload: any) {
    return this.http.post<any>(
      `${environment.studentService}/v1/practices`,
      payload
    );
  }

  getPracticeDetails(pageRequest: { size: 1; page: 0 }) {
    return this.http.get<any>(`${environment.studentService}/v1/practices`, {
      params: {
        ...pageRequest,
      },
    });
  }
  getAllDiagnostics(diagnosticSearchPageRequest: any = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-diagnostics`,
      {
        params: {
          ...diagnosticSearchPageRequest,
        },
      }
    );
  }
  getRating(): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/ratings`);
  }
  submitRating(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/ratings`,
      payload
    );
  }

  setBookMark(req: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/my-bookmark`,
      req
    );
  }

  reportQuestion(reportReasonInfo: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/my-report-question`,
      reportReasonInfo
    );
  }

  getClassWorksInfo(pageRequest: any) {
    return this.http.get<any>(`${environment.studentService}/v1/classworks`, {
      params: {
        ...pageRequest,
      },
    });
  }
  getPracticeByPracticeId(practiceId: string) {
    return this.http.get<any>(
      `${environment.studentService}/v1/practices/${practiceId}`
    );
  }
  getDianosticsById(diagnosticId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-diagnostics/${diagnosticId}`
    );
  }
  createDiagnostic(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/my-diagnostics`,
      payload
    );
  }
  updateDiagnostics(
    diagnosticId: string,
    action: string = '',
    payload: any = {}
  ): Observable<any> {
    return this.http.patch<any>(
      `${environment.studentService}/v1/my-diagnostics/${diagnosticId}?action=${action}`,
      payload
    );
  }

  getAssessmentResult(payload: any, sessionId: string) {
    return this.http.put<any>(
      `${environment.studentService}/v1/my-classWork/${sessionId}`,
      payload
    );
  }
  getStudentStreak() {
    return this.http.get<any>(`${environment.studentService}/v1/streak`);
  }

  getFreeTestSummary(
    studentId: string,
    assessmentType: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-assessments/free-test-summary/${studentId}/${assessmentType}`
    );
  }

  getMyProfile(): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/my-profile`);
  }

  getProducts(reqParams: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/my-products`, {
      params: {
        ...reqParams,
      },
    });
  }
  getLeaderBoardReports(
    stuId: string,
    stuScore: number,
    toplimit: number,
    id: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-classWork/leaderboard/${stuId}/${stuScore}/${toplimit}/${id}`
    );
  }
  getScholarshipAssessment(pageRequest: any): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-available-scholarship-test`,
      {
        params: {
          ...pageRequest,
        },
      }
    );
  }

  // getAssessment(payload: any): Observable<any> {
  //   return this.http.post<any>(
  //     `${environment.studentService}/v1/my-assessments`,
  //     payload
  //   );
  // }

  getFreeTest(pageRequest: any): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-available-free-test`,
      {
        params: {
          ...pageRequest,
        },
      }
    );
  }

  myClassWork(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/my-classWork`,
      payload
    );
  }

  getAllHomework(b2bStudentHomeWorkSearchRequest: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/homeworks`, {
      params: {
        ...b2bStudentHomeWorkSearchRequest,
      },
    });
  }

  getAllClasswork(b2bStudentHomeWorkSearchRequest: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/classworks`, {
      params: {
        ...b2bStudentHomeWorkSearchRequest,
      },
    });
  }

  getAssignDiagnostic(params: any = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/diagnosticworks`,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getMySummaryReport(params: any = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.studentService}/v1/my-summary-report`,
      {
        params: {
          ...params,
        },
      }
    );
  }

  viewNotifications(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.studentService}/v1/view-notifications`,
      payload
    );
  }

  getStudentClassworkSummary(params: any): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/school-work/student-summary`, {
        params: {
          ...params,
        },
      });
  }

  getStudentClassworkScoreOverview(params: any): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/school-work/students/score-overview`, {
        params: {
          ...params,
        },
      });
  }
  getStudentChapterOverview(params: any): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/student-homework-summary/chapters`, {
        params: {
          ...params,
        },
      });
  }

  getSubjectSectionsStudents(params: any): Observable<any> {
    return this.http.get<any>(`${environment.studentService}/v1/student-progress`, {
        params: {
          ...params,
        },
      });
  }
}
